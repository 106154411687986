
export default {
  Drivers: {
    _tag: 'CSidebarNavItem',
    name: 'Drivers',
    phrase: 'nav-drivers-vehicles',
    to: '/lists',
    icon: 'fa-regular fa-steering-wheel',
   roles: ['Admin','Sub Admin'],
    order:10,
  },
}
