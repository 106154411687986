export default {
	// Logout: {
	// 	_tag: 'CSidebarNavItem',
	// 	name: 'Logout',
	// 	phrase: 'logout',
	// 	to: '/logout',
	// 	order: 40,
	// 	icon: 'fas logout-icon',
	// 	badge: {
	// 		color: 'info',
	// 		text: 'NEW'
	// 	}
	// }
};