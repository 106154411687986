export default {
  // Profile: {
  //   _tag: 'CSidebarNavItem',
  //   name: 'notifications',
  //   phrase: 'Profile',
  //   to: '/profile',
  //   order: 2,
  //   icon: 'fa-regular fa-user',
  //  roles: ['Admin','Sub Admin'],
  // },
}
